.login-icon {
  right: 0;
  cursor: pointer;
}

.active {
  background-color: #0479a5 !important;
  border-color: #0479a5 !important;
  color: #fff !important;
}

.active:hover {
  background-color: rgb(27, 28, 41) !important;
  color: white !important;
  border-color: rgb(27, 28, 41) !important;
}

.fc-header-toolbar.fc-toolbar  {
  visibility: hidden !important;
  display: none !important;
}

.visible {
  visibility: visible;
  display: initial;
}

.hidden {
  visibility: hidden;
  display: none;
}

#root {
  overflow-y: hidden;
  overflow-x: hidden;
}

.fc-event-main {
  cursor: pointer;
}

.fc-col-header-cell-cushion {
  text-transform: capitalize;
}

.fc-event-main {
  color: #363636 !important;
  font-size: 12px;
  font-weight: bolder;
}

.fc-daygrid-day-number {
  cursor: pointer;
  text-decoration: underline; 
}

.fc-daygrid-week-number {
  cursor: pointer;
  text-decoration: underline;
}

.fc-list-event.fc-event.fc-event-start.fc-event-end.fc-event-future {
  cursor: pointer;
}

.popover {
  max-width: 720px !important;
  border-radius: 2px !important;
  z-index: 10000 !important;
}

a {
  text-decoration: underline !important;
}

.fc-timegrid-event {
  text-decoration: none !important;
}

a:hover {
  color: inherit !important;
}

a.default {
  color: #1890ff;
}

.rs-picker-toggle-value {
  font-weight: bold;
}

.rs-picker-toggle-textbox {
  font-weight: bold;
}

.rs-picker-toggle-placeholder {
  font-weight: bold;
}

.rc-steps-item-process .rc-steps-item-title::after {
  background-color: rgb(100, 100, 100) !important;
}

.rc-steps-item-wait .rc-steps-item-title::after {
  background-color: rgb(100, 100, 100) !important;
}

.rc-steps-item-title::after {
  margin-top: 3px !important;
}

.rc-steps-item-wait .rc-steps-item-icon {
  border-color: rgb(100, 100, 100) !important;
}

.rc-steps-item-wait .rc-steps-icon {
  color: rgb(100, 100, 100) !important;
}

.rc-steps-item-title {
  font-size: 15px !important;
  font-weight: 500 !important;
  color: rgb(100, 100, 100) !important;
}

.react-datepicker__month-container {
  z-index: 10000 !important;
}

.hstack {
  padding: 1px;
}

.react-datepicker__close-icon::after {
  color: black !important;
  background-color: white !important;
  font-size: 20px !important;
  padding-bottom: 5px !important;
}

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  display: none !important;
  visibility: hidden !important;
}

@media (max-height: 500px) {
  #visit-form-media {
    visibility: hidden !important;
    display: none !important;
  }
}

body {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: white;
  width: 0;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(103, 103, 103);
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(155, 155, 155);
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.css-1pahdxg-control {
  border-color: #dbdbdb !important;
}

.css-1pahdxg-control:hover {
  border-color: #dbdbdb !important;
}

.fc-scroller.fc-scroller-liquid-absolute {
  overflow-y: hidden !important;
}

.fc-scroller.fc-scroller-liquid {
  overflow-x: auto !important;
}

.fc-list-event-title {
  cursor: pointer !important;
}

.fc-daygrid-event {
  cursor: pointer !important;
}

.react-datepicker__time-list-item {
  font-weight: bold;
  font-size: larger;
}

.react-datepicker__time-container {
  width: 100px;
}

.react-datepicker__time-box {
  width: 100px !important;
}

.react-datepicker__day {
  font-size: larger;
  font-weight: bolder;
}

.react-datepicker__day-name {
  text-transform: capitalize;
  font-size: larger;
  font-weight: bold;
}

.fc-popover-body {
  max-height: 100px;
  overflow: scroll;
}


@media (min-width: 420px) {
  .mobile-eye {
    visibility: hidden !important;
    display: none !important;
  }
}